import * as React from 'react'
import cn from 'classnames'
import type { EventHandlers, Permissions } from './SkillsGrid.types'
import { TSkill, TCategory, TSkillVariant } from '../../../../types/entities'
import { DragAndDropProvider } from './drag-and-drop'
import { AddCategoryColumn } from './AddCategoryColumn'
import { CategoryColumns } from './CategoryColumns'
import { SkillsProvider } from './skills'
import styles from './Grid.module.scss'
import { EmptyState } from './EmptyState'
import { SkillsDrawer, SkillsDrawerProps } from '../SkillsDrawer'
import { DragOverlay } from './DragOverlay'

export type GridProps = {
  skills: readonly TSkill[]
  skillVariants: readonly TSkillVariant[]
  categories: readonly TCategory[]
  eventHandlers?: EventHandlers
  permissions?: Permissions
  skillsDrawerProps?: SkillsDrawerProps
}

export const Grid = (props: GridProps) => {
  const { skillsDrawerProps, ...restProps } = props

  if (
    props.skills.length === 0 &&
    props.categories.length === 0 &&
    !props.permissions?.allowEdit
  ) {
    return <EmptyState />
  }

  const hasCategoryDescriptions = props.categories.some(
    (category) => category.description
  )

  return (
    <SkillsProvider {...restProps}>
      <DragAndDropProvider>
        {skillsDrawerProps && (
          <SkillsDrawer
            frameworkSkills={props.skills}
            overlay={false}
            {...skillsDrawerProps}
          />
        )}
        <div
          className={cn(styles.grid, {
            [styles.hasCategoryDescriptions]: hasCategoryDescriptions,
          })}
        >
          <CategoryColumns />
          <AddCategoryColumn />
        </div>
        <DragOverlay />
      </DragAndDropProvider>
    </SkillsProvider>
  )
}
