import { arrayMove } from '@dnd-kit/sortable'
import * as React from 'react'
import type {
  TSkill,
  TCategory,
  TSkillVariant,
} from '../../../../../types/entities'
import type { EventHandlers, Permissions } from '../SkillsGrid.types'
import { removeItem } from '../../../../../utils/array-helpers'
import { SkillsContext } from './SkillsContext'
import {
  getCategorySkillIdMap,
  createSkillsDictionary,
  getCategoryInCategories,
  getCategorySkillVariantIdMap,
  createSkillVariantsDictionary,
} from './utils'
import { UNCATEGORISED_ID } from '.'

type SkillsProviderProps = {
  children: React.ReactNode
  skills: readonly TSkill[]
  skillVariants: readonly TSkillVariant[]
  categories: readonly TCategory[]
  eventHandlers?: EventHandlers
  permissions?: Permissions
}

export const SkillsProvider = (props: SkillsProviderProps) => {
  const {
    children,
    eventHandlers,
    permissions,
    skills,
    skillVariants,
    categories,
  } = props

  /**
   * Dictionary containing stringified skill ids as keys and the raw skill itself as the value
   */
  const skillsDictionary = React.useMemo(
    () => createSkillsDictionary(skills),
    [skills]
  )

  const skillVariantsDictionary = React.useMemo(
    () => createSkillVariantsDictionary(skillVariants),
    [skillVariants]
  )

  const hasUncategorisedSkills = React.useMemo(
    () => skills.some((skill) => skill.categoryId === null),
    [skills]
  )

  /**
   * Function to get a category by its stringified id
   */
  const getCategory = React.useCallback(
    (categoryId: string) => {
      return getCategoryInCategories(categoryId, categories)
    },
    [categories]
  )

  // current state of categorised skills, can be updated internally for temporary drag states
  const [categorisedSkillIds, setCategorisedSkillIds] = React.useState(
    getCategorySkillIdMap(categories, skills)
  )

  // function for resetting the categorisedSkillIds map to source
  const resetCategorisedSkillIds = React.useCallback(() => {
    const newMap = getCategorySkillIdMap(categories, skills)

    setCategorisedSkillIds(newMap)
  }, [categories, skills])

  // Variants //

  const [categorisedSkillVariantIds, setCategorisedSkillVariantIds] =
    React.useState(getCategorySkillVariantIdMap(categories, skillVariants))

  const resetCategorisedSkillVariantIds = React.useCallback(() => {
    const newMap = getCategorySkillVariantIdMap(categories, skillVariants)

    setCategorisedSkillVariantIds(newMap)
  }, [categories, skills])

  React.useEffect(() => {
    resetCategorisedSkillIds()
    resetCategorisedSkillVariantIds()
  }, [categories, skills])

  /**
   * Memoized stringified category ids - these are pulled directly from the local copy of categorisedSkillIds which means these can be looped over to render categories in the correct sort order
   */
  const categoryIds = React.useMemo((): string[] => {
    return Array.from(categorisedSkillIds.keys())
  }, [categorisedSkillIds])

  /**
   * Function for getting stringified skill ids within a category - these will be updated internally when the sort order changes via dragging
   */
  const getSkillIdsInCategory = (categoryId: string): string[] => {
    return categorisedSkillIds.get(categoryId) || []
  }

  const getSkillVariantIdsInCategory = (categoryId: string): string[] => {
    return categorisedSkillVariantIds.get(categoryId) || []
  }

  const showUncategorisedColumn = React.useMemo((): boolean => {
    const hasOtherCategories =
      categoryIds.filter((id) => id !== UNCATEGORISED_ID).length > 0
    return hasUncategorisedSkills || !hasOtherCategories
  }, [categoryIds, hasUncategorisedSkills])

  /**
   * Function for getting a skill by its stringified id from the dictionary - these skills should reflect the source values so shouldn't be modified
   */
  const getSkill = (skillId: string): TSkill | null => {
    return skillsDictionary[skillId] ?? null
  }

  const getSkillVariant = (skillVariantId: string): TSkillVariant | null => {
    return skillVariantsDictionary[skillVariantId] ?? null
  }

  /**
   * Function for moving a category from an index to a different index - used for dragging and dropping
   */
  const reorderCategory = React.useCallback(
    async (id: string, targetId: string): Promise<void> => {
      const foundIndex = categoryIds.indexOf(id)
      const targetIndex = categoryIds.indexOf(targetId)

      if (foundIndex === -1)
        throw new Error(`Category id ${id} not found in categoryIds`)
      if (targetIndex === -1)
        throw new Error(
          `Target category id ${targetId} not found in categoryIds`
        )

      const sortedCategoryIds = arrayMove(categoryIds, foundIndex, targetIndex)

      // filter out null category since we add this back in during the map
      const newCategories = sortedCategoryIds.flatMap((categoryId, index) => {
        const category = getCategory(categoryId)
        if (!category) return []
        return { ...category, listPosition: index }
      })

      const newMap = getCategorySkillIdMap(newCategories, skills)
      setCategorisedSkillIds(newMap)

      const success = await eventHandlers?.onReorderCategories?.(
        sortedCategoryIds.filter((c) => c !== UNCATEGORISED_ID)
      )
      if (!success) {
        resetCategorisedSkillVariantIds()
        resetCategorisedSkillIds()
      }
    },
    [categoryIds]
  )

  const moveSkill = (
    skillId: string,
    targetCategoryId: string,
    targetIndex: number
  ) => {
    const categoryId = Array.from(categorisedSkillIds.keys()).find(
      (categoryId) => categorisedSkillIds.get(categoryId)?.includes(skillId)
    )

    if (typeof categoryId !== 'string') {
      throw new Error(`Existing category not found for skill id: ${skillId}`)
    }

    const newMap = new Map(categorisedSkillIds)
    const newCategorySkillIds = [...getSkillIdsInCategory(targetCategoryId)]

    if (newCategorySkillIds.includes(skillId)) {
      // Reorder in the existing category
      const currentIndex = newCategorySkillIds.indexOf(skillId)
      const newSkills = arrayMove(
        newCategorySkillIds,
        currentIndex,
        targetIndex
      )
      newMap.set(targetCategoryId, newSkills)
    } else {
      // Add to the new category
      newCategorySkillIds.splice(targetIndex, 0, skillId)
      newMap.set(targetCategoryId, newCategorySkillIds)

      // Delete the existing skill
      const skillsInCategory = [...getSkillIdsInCategory(categoryId)]
      removeItem(skillsInCategory, skillId)
      newMap.set(categoryId, skillsInCategory)
    }

    setCategorisedSkillIds(newMap)
  }

  const moveSkillVariant = (
    skillVariantId: string,
    targetCategoryId: string,
    targetIndex: number
  ) => {
    const categoryId = Array.from(categorisedSkillVariantIds.keys()).find(
      (categoryId) =>
        categorisedSkillVariantIds.get(categoryId)?.includes(skillVariantId)
    )

    if (typeof categoryId !== 'string') {
      throw new Error(
        `Existing category not found for skill id: ${skillVariantId}`
      )
    }

    const newMap = new Map(categorisedSkillVariantIds)
    const newCategorySkillVariantIds = [
      ...getSkillVariantIdsInCategory(targetCategoryId),
    ]

    if (newCategorySkillVariantIds.includes(skillVariantId)) {
      // Reorder in the existing category
      const currentIndex = newCategorySkillVariantIds.indexOf(skillVariantId)
      const newSkillVariants = arrayMove(
        newCategorySkillVariantIds,
        currentIndex,
        targetIndex
      )
      newMap.set(targetCategoryId, newSkillVariants)
    } else {
      // Add to the new category
      newCategorySkillVariantIds.splice(targetIndex, 0, skillVariantId)
      newMap.set(targetCategoryId, newCategorySkillVariantIds)

      // Delete the existing skill
      const skillVariantsInCategory = [
        ...getSkillVariantIdsInCategory(categoryId),
      ]
      removeItem(skillVariantsInCategory, skillVariantId)
      newMap.set(categoryId, skillVariantsInCategory)
    }

    setCategorisedSkillVariantIds(newMap)
  }

  return (
    <SkillsContext.Provider
      value={{
        eventHandlers,
        permissions,
        categoryIds,
        getSkill,
        getSkillVariant,
        getCategory,
        categorisedSkillIds,
        categorisedSkillVariantIds,
        getSkillIdsInCategory,
        getSkillVariantIdsInCategory,
        resetCategorisedSkillIds,
        resetCategorisedSkillVariantIds,
        reorderCategory,
        moveSkill,
        moveSkillVariant,
        showUncategorisedColumn,
      }}
    >
      {children}
    </SkillsContext.Provider>
  )
}
