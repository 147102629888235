import {
  CollisionDetection,
  pointerWithin,
  rectIntersection,
} from '@dnd-kit/core'

export const customCollisionDetectionStrategy: CollisionDetection = (props) => {
  const { droppableContainers } = props
  const skillsListRect = droppableContainers.filter((container) =>
    `${container.id}`.includes('skillList')
  )

  if (skillsListRect) {
    const intersectingSkillsDrawerRect = pointerWithin({
      ...props,
      droppableContainers: skillsListRect,
    })

    if (intersectingSkillsDrawerRect.length > 0) {
      return intersectingSkillsDrawerRect
    }
  }

  const otherRects = droppableContainers.filter(
    ({ id }) => !`${id}`.includes('skillList')
  )

  return rectIntersection({ ...props, droppableContainers: otherRects })
}
