import * as React from 'react'
import cn from 'classnames'
import { AddResourceButton } from '../../../molecules/AddResourceButton'
import styles from './Grid.module.scss'
import { useSkills } from './skills'

export const AddCategoryColumn = () => {
  const { eventHandlers, permissions } = useSkills()

  if (!permissions?.allowEdit) return null

  return (
    <div className="py-4 pr-4">
      <div className={cn(styles.column)}>
        <AddResourceButton
          className="p-1"
          onClick={eventHandlers?.onAddCategory}
        >
          Add a category
        </AddResourceButton>
      </div>
    </div>
  )
}
