import * as React from 'react'
import styles from './Grid.module.scss'
import achievements from '../../../../images/achievements.svg'

export const EmptyState = () => (
  <div className={styles.emptyState}>
    <img src={achievements} className={styles.image} />
    <h3 className={styles.title}>No skills yet</h3>
    <p className={styles.content}>Get an admin to create some!</p>
  </div>
)
