import * as React from 'react'
import {
  horizontalListSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable'
import { idWithPrefix, prefixedIds } from './drag-and-drop'
import { CategoryColumn } from './CategoryColumn'
import styles from './Grid.module.scss'
import { useSkills, UNCATEGORISED_ID } from './skills'

/**
 * Wrapper around categorised columns (including uncategorised). This component wraps categories in a sortable context and includes drag overlays for skills/categories
 */
export const CategoryColumns = () => {
  const { categoryIds, showUncategorisedColumn, getCategory } = useSkills()

  return (
    <div className={styles.columns}>
      <SortableContext
        id="category-columns"
        items={prefixedIds(categoryIds, 'category')}
        strategy={horizontalListSortingStrategy}
      >
        {categoryIds.map((categoryId) => {
          if (categoryId === UNCATEGORISED_ID && !showUncategorisedColumn)
            return null

          const lockedCategory = getCategory(categoryId)?.locked
          return (
            <React.Fragment key={idWithPrefix(categoryId, 'category')}>
              <CategoryColumn
                lockedCategory={lockedCategory}
                categoryId={categoryId}
              />
            </React.Fragment>
          )
        })}
      </SortableContext>
    </div>
  )
}
