import * as React from 'react'
import { DragOverlay as BaseDragOverlay } from '@dnd-kit/core'
import { useDragAndDrop } from './drag-and-drop'
import { CategoryColumn } from './CategoryColumn'
import { SkillCard } from './SkillCard'
import { SkillCard as DrawerSkillCard } from '../SkillsDrawer/SkillCard'
import { SkillVariantCard } from './SkillVariantCard'
import { CollectionCard } from '../CollectionCard'

export const DragOverlay = () => {
  const {
    draggedCategoryId,
    draggedSkillId,
    draggedSkillVariantId,
    draggedDrawerSkill,
    draggedCollection,
  } = useDragAndDrop()

  return (
    <BaseDragOverlay
      dropAnimation={
        draggedDrawerSkill ? null : { duration: 0, easing: 'ease' }
      }
      zIndex={1050}
    >
      {draggedCategoryId ? (
        <CategoryColumn isDragOverlay categoryId={draggedCategoryId} />
      ) : null}
      {draggedSkillId ? (
        <SkillCard isDragOverlay skillId={draggedSkillId} />
      ) : null}
      {draggedSkillVariantId ? (
        <SkillVariantCard
          isDragOverlay
          skillVariantId={draggedSkillVariantId}
        />
      ) : null}
      {draggedDrawerSkill ? (
        <DrawerSkillCard
          allowEdit
          isDragOverlay
          skill={draggedDrawerSkill.skill}
        />
      ) : null}
      {draggedCollection ? (
        <CollectionCard collection={draggedCollection} isDragOverlay />
      ) : null}
    </BaseDragOverlay>
  )
}
