import * as React from 'react'
import {
  DndContext,
  DragCancelEvent,
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
} from '@dnd-kit/core'
import { DragAndDropContext } from './DragAndDropContext'
import { useSkills } from '../skills'
import {
  getTargetContainerId,
  getDragType,
  getId,
  getTargetId,
  getTargetIndex,
} from './utils'
import type { TCollection, TSkill } from '../../../../../types/entities'
import type { SkillType } from '../../SkillsDrawer/SkillsDrawer.types'
import { customCollisionDetectionStrategy } from './customCollision'
import { useDndSensors } from '../../../../../hooks/use-dnd-sensors'

type DragAndDropProviderProps = {
  children: React.ReactNode
}

const screenReaderInstructions = {
  draggable:
    'Press enter to view the detail of a skill. You can reorder skills by dragging them; press space bar to start a drag. When dragging you can use the arrow keys to move the item around and escape to cancel. Some screen readers may require you to be in focus mode or to use your pass through key.',
}

export const DragAndDropProvider = (props: DragAndDropProviderProps) => {
  const { children } = props

  const {
    reorderCategory,
    categorisedSkillIds,
    categorisedSkillVariantIds,
    moveSkill,
    moveSkillVariant,
    resetCategorisedSkillIds,
    resetCategorisedSkillVariantIds,
    eventHandlers,
  } = useSkills()

  const sensors = useDndSensors()

  const [draggedSkillId, setDraggedSkillId] = React.useState<string | null>(
    null
  )

  const [draggedSkillVariantId, setDraggedSkillVariantId] = React.useState<
    string | null
  >(null)

  const [draggedCategoryId, setDraggedCategoryId] = React.useState<
    string | null
  >(null)

  const [draggedDrawerSkill, setDraggedDrawerSkill] = React.useState<{
    skill: TSkill
    skillType?: SkillType
  } | null>(null)

  const [draggedCollection, setDraggedCollection] =
    React.useState<TCollection | null>(null)

  const handleDragStart = React.useCallback((e: DragStartEvent) => {
    const type = getDragType(e)
    const id = getId(e)

    if (type === 'category') setDraggedCategoryId(id)
    if (type === 'skill') setDraggedSkillId(id)
    if (type === 'skillVariant') setDraggedSkillVariantId(id)

    // 'external' items ie skill drawer
    if (type === 'drawerSkill') {
      const skill: TSkill = e.active.data.current?.skill
      const skillType: SkillType = e.active.data.current?.skillType
      if (!skill) throw new Error('Skill data missing from sortable skill card')

      setDraggedDrawerSkill({ skill, skillType })
    }
    if (type === 'collection') {
      const collection: TCollection = e.active.data.current?.collection
      if (!collection)
        throw new Error('Collection data missing from sortable skill card')

      setDraggedCollection(collection)
    }
  }, [])

  const handleDragOver = (e: DragOverEvent) => {
    const id = getId(e)
    const targetId = getTargetId(e)

    if (id === targetId) return

    const type = getDragType(e)

    if (type === 'skill') {
      const targetContainerId = getTargetContainerId(e)
      if (!targetContainerId) return
      // TODO: better logic for returning if the card is over the drawer
      if (targetContainerId === 'Sortable') return

      const targetIndex = getTargetIndex(e)
      if (typeof targetIndex !== 'number') return

      moveSkill(id, targetContainerId, targetIndex)
    }

    if (type === 'skillVariant') {
      const targetContainerId = getTargetContainerId(e)
      if (!targetContainerId) return
      // TODO: better logic for returning if the card is over the drawer
      if (targetContainerId === 'Sortable') return

      const targetIndex = getTargetIndex(e)
      if (typeof targetIndex !== 'number') return

      moveSkillVariant(id, targetContainerId, targetIndex)
    }
  }

  const handleDragEnd = async (e: DragEndEvent) => {
    setDraggedCategoryId(null)
    setDraggedSkillId(null)
    setDraggedSkillVariantId(null)
    setDraggedDrawerSkill(null)
    setDraggedCollection(null)

    if (!e.over) return
    const id = getId(e)
    const targetId = getTargetId(e)
    const type = getDragType(e)

    if (type === 'category' && targetId) reorderCategory(id, targetId)

    // these are updated internally from dragging over
    if (type === 'skillVariant') {
      const success = await eventHandlers?.onReorderSkillVariants?.(
        categorisedSkillVariantIds
      )
      if (!success) resetCategorisedSkillVariantIds()
    }

    if (type === 'drawerSkill') {
      const categoryId = getTargetContainerId(e)
      const skill: TSkill = e.active.data.current?.skill
      if (!skill || !categoryId) return resetCategorisedSkillVariantIds()

      const success = await eventHandlers?.onAddSkillToCategory?.(
        skill,
        categoryId,
        'drag'
      )
      if (!success) console.log('adding skill to category')
      if (!success) resetCategorisedSkillVariantIds()
    }

    if (type === 'collection') {
      const categoryId = getTargetContainerId(e)
      const collection: TCollection = e.active.data.current?.collection
      if (!collection || !categoryId) return resetCategorisedSkillVariantIds()

      const success = await eventHandlers?.onAddCollection?.(
        collection,
        categoryId,
        'drag'
      )
      if (!success) resetCategorisedSkillVariantIds()
    }
  }

  const handleDragCancel = (_e: DragCancelEvent) => {
    resetCategorisedSkillVariantIds()
  }

  return (
    <DragAndDropContext.Provider
      value={{
        draggedSkillId,
        draggedSkillVariantId,
        draggedCategoryId,
        draggedDrawerSkill,
        draggedCollection,
      }}
    >
      <DndContext
        accessibility={{
          screenReaderInstructions,
        }}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        onDragCancel={handleDragCancel}
        sensors={sensors}
        collisionDetection={customCollisionDetectionStrategy}
      >
        {children}
      </DndContext>
    </DragAndDropContext.Provider>
  )
}
